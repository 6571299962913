const heartbeat_interval = 30000;
const reconnectionAttempsThreshold = 10;

class CustomWebSocket {
    /**
     *
     * @param {String} wsId  - WebSocket identifier
     * @param {String} url  - WebSocket url
     * @param {String | String[]} [protocols]  - WebSocket creation protocols
     * @param {Map} events - key one of {onopen,onmessage,onclose,onerror}, value is a callback function
     * @param {*} store - project redux store
     * @throws an error if it can establish a connection to the given url
     */
    constructor(wsId, url, protocols, events, store) {
        this.wsId = wsId;
        this.events = events;
        this.store = store;
        this.url = url;
        this.protocols = protocols;

        this.websocket = new WebSocket(url, protocols);
        this.websocket.onopen = this.onOpen;
        this.websocket.onmessage = this.onMessage;
        this.websocket.onerror = this.onError;
        this.websocket.onclose = this.onClose;

        this.reconnectAttempts = 0;
        this.heartBeatInterval = null;
        this.connectionActive = true;
    }

    /*------------------------------------------ Websocket event handler ---------------------------------------------- */

    /**
     * An event listener to be called when the connection is opened.
     *
     * @param {Event} event - websocket onopen event
     */
    onOpen = (event) => {
        this.reconnectAttempts = 0;
        this.connectionActive = true;
        this.startHeartbeat();
        this.authenticate();
        this.fireCallBack("onopen", event);
    }

    /**
     * An event listener to be called when a message is received from the server.
     *
     * @param {Event} event - websocket onmessage event
     */
    onMessage = (event) => {
        this.fireCallBack("onmessage", event);
    }

    /**
     * An event listener to be called when the connection is closed.
     *
     * @param {Event} event - websocket onclose event
     */
    onClose = (event) => {
        this.reconnectAttempts++;
        this.connectionActive = false;
        console.log('Socket was closed. Reconnect will be attemped. Reconnect attempt number: '+this.reconnectAttempts);

        if(this.reconnectAttempts <= reconnectionAttempsThreshold){
            setTimeout(() => {
                this.reconnect();
            },1000);
        } else {
            clearInterval(this.heartBeatInterval);
            this.fireCallBack("onclose", event);
        }
    }

    /**
     * An event listener to be called when an error occurs.
     *
     * @param {Event} event - websocket onerror event
     */
    onError = (event) => {
        this.fireCallBack("onerror", event);
    }

    /**
     * Method  to close a websocket connection
     * @throws will throw an error if the websocket can't close
     */
    close() {
        this.websocket.close();
    }

    /*------------------------------------------ Callback event handler ---------------------------------------------- */

    /**
     * Method to add a callback function to a websocket event
     *
     * @param {String} eventName - One of {onopen,onmessage,onclose,onerror}
     * @param {Function} callback - callback function when the event happens
     */
    addEvent(eventName, callback) {
        if (this.isEventSupported(eventName)) {
            this.events.set(eventName, callback);
        } else {
            console.log("The event " + eventName + " isn't supported");
        }
    };

    /**
     * Method that checks if the given event name is supported
     *
     * @param {String} eventName - One of {onopen,onmessage,onclose,onerror}
     * @returns {boolean} True if event is one of the supported websocket events
     */
    isEventSupported(eventName) {
        return eventName === 'onopen' || eventName === 'onmessage' || eventName === 'onerror' || eventName === 'onclose';
    };

    /**
     * Method to remove a callback function to a websocket event
     *
     * @param {String} eventName - One of {onopen,onmessage,onclose,onerror}
     */
    removeEvent(eventName) {
        this.events.delete(eventName);
    };

    /**
     * Method the calls the callback function of the given eventName and passes as arguments the websocket id and the triggered event
     *
     * @param {String} eventName - One of {onopen,onmessage,onclose,onerror}
     * @param {*} event - event fired by the websocket for the respective event
     */
    fireCallBack(eventName, event) {
        let callback = this.events.get(eventName);
        if (callback)
            callback(this.wsId, event)
    }

    /*------------------------------------------ Messages event handler ---------------------------------------------- */

    /**
     * Method to authenticate an websocket connection
     * It will use the accessToken already present in the application
     */
    authenticate() {
        this.sendMessage({ method: "authenticate", payload: this.store.getState().shell.accessToken })
    };

    /**
     * Enqueues data to be transmitted.
     *
     * @param {*} message
     * @throws will throw an error if it can process the message or if the websocket can't send the message
     */
    sendMessage(message) {
        const stringMessage = JSON.stringify(message);
        this.websocket.send(stringMessage);
    };

    /*------------------------------------------ Heart Beat & Reconnect  ---------------------------------------------- */
    /**
     * Heartbeat message to keep the socket open 
     */
    startHeartbeat() {
        this.heartBeatInterval = setInterval(() => {
            if(this.connectionActive){
                this.websocket.send("-heartbeat-");
            }
        }, heartbeat_interval)
    }

    /**
     * Reconnect funcion to attempt tp restore the websocket state after a close
     */
    reconnect = () => {
        this.websocket = new WebSocket(this.url, this.protocols);
        this.websocket.onopen = this.onOpen;
        this.websocket.onmessage = this.onMessage;
        this.websocket.onerror = this.onError;
        this.websocket.onclose = this.onClose;
    }
}

export default CustomWebSocket;