import CustomWebSocket from "./CustomWebsocket";

export default class WebSocketProvider {

    /**
     * Initiates the websocket's map
     */
    constructor() {
        this.webSockets = new Map();
    }

    /**
     * Creates a new CustomWebSocket gives it a random id and adds it to webSockets map
     *
     * @param {String} url - to where the websocket will connect
     * @param {String | String[]} protocols - Protocol of the websocket
     * @param {Map} events shape-> key:eventName value:callBack} events to add for this websocket
     * @returns {String|boolean} id of the websocket (used for other actions) if successful or false is there is an error 
     */
    connect(url, protocols, events) {
        const wsId = Math.random() * 1000;
        try {
            this.webSockets.set(wsId, new CustomWebSocket(wsId.toString(), url, protocols, events, this.store));
        } catch (err) {
            console.log("Error when trying to connect to ->" + url, err);
            return false
        };
        return wsId.toString();
    };

    /**
     * Tries to close the websocket confection and if its successful removes it from the websocket map
     *
     * @param {string} wsId - id of the websocket to close
     * @return {string|boolean} returns true if no error occurs or returns a string with the error
     */
    close(wsId) {
        try {
            this.webSockets.get(wsId).close();
            this.webSockets.delete(wsId);
            return true
        } catch (err) {
            return "Error: " + err
        }
    }

    /**
     * Sends an string message for the given websocket
     *
     * @param {string} wsId - id of the websocket to send the message
     * @param {object|string} message - object (will be parsed) or string to send
     * @return {string|boolean} returns true if no error occurs or returns a string with the error
     */
    sendMessage(wsId, message) {
        try {
            this.webSockets.get(wsId).sendMessage(message);
            return true
        } catch (err) {
            return "Error: " + err
        }
    };

    /**
     * Adds a callback function to a websocket event
     *
     * @param {string} wsId - the id of the websocket to add the callback
     * @param {string} eventName - One of {onopen,onmessage,onclose,onerror}
     * @param {function} callback - callback function to be called en the websocket event is triggered
     */
    addEvent(wsId, eventName, callback) {
        this.webSockets.get(wsId).addEvent(eventName, callback)
    };

    /**
     * Removes a callback function to a websocket event
     *
     * @param {string} wsId - the id of the websocket to add the callback
     * @param {string} eventName - One of {onopen,onmessage,onclose,onerror}
     */
    removeEvent(wsId, eventName) {
        this.webSockets.get(wsId).removeEvent(eventName)
    };

    /**
     * Sets the store, that is given to every CustomWebsocket
     *
     * @param store - project store
     */
    setStore(store) {
        this.store = store;
    }
}
